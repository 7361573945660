import Button from "component/ui/button";
import { IMAGEDJ, Logo2 } from "config/image";
import { FileContext } from "context/file-upload-context";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";
const fileTypes = ["MP3","WAV", "ZIP"];
export default function CustomerUpload(props) {
      const { route } = props;
  const params = useParams();
  const { id } = params;
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const [fileR, resetFile] = useState("r");
  const [downloadFile, setDownloadFiles] = useState([]);
  const [files,  setFiles] =  useState([])
  const [sampleFile, setSampleFile] = useState({});
  const { File, uploadFile , getFiles: getFilesAws,} = useContext(FileContext);
  const [email,setEmail] = useState("")



 
  const getAllFiles = () => {
    getFilesAws({ Prefix: params.email }).then((data) => {
      setFiles(data);
      // filteredData(data);
    });
    return () => {};
  };
  useEffect(() => {
    getAllFiles();

    return () => {};
  }, []);

  
    const getInitialState = (state = {}) => {
      return {
        file_name: "",
        file: [],
        file_type: "product",
      };
    };
    const formik = useFormik({
      initialValues: getInitialState(),
      validationSchema: yup.object({
        // file_name: yup
        //   .string("Enter File Name")
        //   .required("Please Enter File Name"),
        file: yup.mixed().required("File is required"),
        // email: yup.string("Enter email").required("Email is required"),
      }),
      onSubmit: async (values) => {
      Object.values( values.file)?.map((file)=>{
         
          let requestPayload = {
            fileToUpload: file, 
            file_type: file.file_type,
            folder:`${params.email}`,
            cb:()=>{
              getAllFiles();
            }
          };
    
       
          formik.resetForm(getInitialState());
          uploadFile(
            requestPayload,
          
           
          );
        })
      
        resetFile(Date.now());
        
      },
    });
  
    const handleChange = (file) => {
      // setFile(file);
      file && formik.setFieldValue("file", file);

    };
  return (
   <div className='wrapper-file wrapper-full'>
        <div className='upload-block '>
        <div className="top mb-3 " style={{display:"flex", justifyContent:"center"}} >
                <img src={Logo2} alt="Lucid" style={{ width: "250px" }} />
              </div>
              <div>
                <img width="100%" src={IMAGEDJ}></img>
              </div>
              <div className="mb-3 mt-3">
                <h5> Welcome, <span style={{color:"#0096FF",textTransform:"capitalize"}}> {params.firstName}! </span></h5>
                <p style={{fontSize:"16px"}}>
                I'm looking forward to considering your song for mixing in one of my YouTube tutorials. Due to the number of submissions, it's not possible to mix every song. But, I do my best to listen to as many as I can.

<p className="mt-3"  style={{fontSize:"16px"}}>Follow the directions below to upload your stems via WAV, MP3, or Zip files. Thank you!</p>


                </p>
              </div>
              <form onSubmit={formik.handleSubmit}>
              <div className='drop-container card'>
                    <div className="drop-file-container card-body ">
                        <lable>Product File</lable>
                        <div className='upload-input mb-3 mt-2'>
                            <FileUploader

                              multiple={true}
                              handleChange={handleChange}
                              name="file"
                              types={fileTypes}
                            />
                            {/* <p style={{color:"red",fontSize:"12px"}}>Audio file should in mp3 and less than 5 MB</p> */}
                        </div>  
                        <div className="ml-0">
                          <ul className="pl-3"> 
                            <li className="insr">Please ensure that your audio files don't contain any malware or viruses </li>
                            <li className="insr">Do not close your tab or browser while uploading audio files, or you'll have to re-upload the files.</li>
                            <li className="insr">Only upload MP3, WAV, or Zip files.</li>
                            <li className="insr">The maximum file size allowed is 3 GB.</li>
                            <li className="insr">If your upload fails, click the link in your email to re-upload.</li>

                            
                          </ul>
                        </div>
                        <Button type="submit" disabled={!formik.values.file?.length>0}>
                          Upload 
                        </Button>
                    </div>
                </div>
              </form>

        </div>
       {files?.length>0 && <div className="upload-container">
            <h5 className="mb-2">Uploaded Files</h5>
            <div className="files">
              <ul className="files-wrapper card">
                  {files?.map((file)=>{
                    return !file?.Key?.includes("final") &&<li>
                      {file?.Key?.replace(`${params.email}/`,"")}
                    </li>
                  })}
              </ul>
              
            </div>
        </div>}
   </div>
  )
}




