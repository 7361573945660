import Button from 'component/ui/button'
import FileUploadWrapper from 'hoc/filter-upload-wrapper'
import React, { useContext, useEffect, useState } from 'react'
import AdminUpload from 'view/admin-upload/admin-upload'
import List from './list/list'
import Card from 'component/ui/card/card'
import BCard from 'react-bootstrap/Card'
import { Logo2 } from 'config/image'
import { FileContext } from 'context/file-upload-context'
import ListFolder from './list/folder/list-folder'
import { useParams } from "react-router-dom";



export default function Dashbord(route) {
  const [layout,setFileLayout] = useState("list")
  const [path,setPath ] = useState([])
  const [files,  setFiles] =  useState([])
  const { File, uploadFile , getFiles: getFilesAws,} = useContext(FileContext);
    const params=  useParams()
  const toggleLayout = ()=>{  
    setFileLayout(layout === "list" ? "file" : "list")
  }
  const getAllFiles = (path) => {
    
    console.log(path)
    getFilesAws(path?.length>1?{
      Prefix:path?.slice(1).map((item)=>item.name).join("/"),
    }:{ }).then((data) => {
      setFiles(data);
      // filteredData(data);
    });
    return () => {};
  };



  
  useEffect(()=>{

let path= [{name:"Home",path:"/admin/dashboard"}]





    if(route?.match?.params?.params1){
      path.push({name:route?.match?.params?.params1,path:"/admin/dashboard/"+route?.match?.params?.params1})
    }
    if(route?.match?.params?.params2){
      // path
      path.push({name:route?.match?.params?.params2,path:"/admin/dashboard/"+route?.match?.params?.params1+"/"+route?.match?.params?.params2})
    }
    if(route?.match?.params?.params3){
      // path
      path.push({name:route?.match?.params?.params3,path:"/admin/dashboard/"+route?.match?.params?.params1+"/"+route?.match?.params?.params2+"/"+route?.match?.params?.params3})
    }

    setPath(path)


    console.log(":ewfef"  ,route?.match?.params)
 
    getAllFiles(path);
  },[route?.match?.params])

  // useEffect(()=>{

    
  //   path?.length>0 &&
  // },[path])
 

  
  return (
    <div>
      <div>
      <BCard>
        <div className='d-flex align-items-center justify-content-between'>
        <div className='pl-3'>
          <img alt="Lucid" style={{ width: "200px" }} src={Logo2}></img>
        </div>
          <div className="d-flex justify-content-end pb-3 ">
            <Button className={"mt-3 mr-3"} style={{width:"fit-content"}} onClick={toggleLayout}>Add File</Button>
           <div  title='Logout'> <Button onClick={()=>{
              localStorage.clear()
              window.location.href="/admin"
           }} variant='danger' className={"mt-3 mr-3"} style={{width:"fit-content"}}>
              <i className='fa fa-sign-out'></i>
            </Button></div>
            </div>
           
        </div>
      </BCard>
      </div>
       { layout==="file" &&  <div>
        <div className='layout'>
          <AdminUpload path={path} fileUpload={getAllFiles} toggle={toggleLayout}> </AdminUpload>
        </div>
      </div>}

      { layout==="list" &&  <div>
    <div className='pl-3'>
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            {path.map((item,index)=>{
              return <div key={index} className='d-flex align-items-center'>
                <a href={item.path} className='mr-2'>{item.name}</a>
                {index !== path.length-1 && <div className='mr-2'>/</div>}
              </div>
            })}
          </div>
        
        </div>
      </div>
      <Card bodyClass="over-flow-i" fixed ><ListFolder path={path} files={files}></ListFolder></Card>
    </div>
      </div>}
    </div>
  )
}
