import clsx from "clsx";
import React from "react";

const Input = ({
  dom = {},
  label = "",
  error = "",
  errorMessage,
  postFix,
  required = false,
  prependText,
  desc = "",
  descClass = "",
  ...props
}) => {
  return (
    <>
      <div>
        {label && (
          <label className="">
            {label}
            {required === true ? <span className="text-danger">*</span> : null}
          </label>
        )}
        {desc && (
          <label className={clsx(descClass, "text-desc")}>
            &nbsp;
            {desc}
          </label>
        )}
      </div>
      <div
        className={clsx(
          "form-group navbar-form search-form relative",
          prependText ? "input-group" : ""
        )}
      >
        {prependText && (
          <div className="input-group-prepend">
            <label className="input-group-text">{prependText}</label>
          </div>
        )}

        {dom.type == "area" ? (
          <textarea
            {...dom}
            className={`form-control h-auto ${dom.className || ""}  ${
              error && "parsley-error"
            }  `}
            /* value={email} */
          />
        ) : (
          <input
            {...dom}
            className={`form-control ${dom.className || ""}  ${
              error && "parsley-error"
            }  `}
            /* value={email} */
          />
        )}
        {postFix && postFix()}
      </div>
      {error ? (
        <ul className="parsley-errors-list filled" id="parsley-id-29">
          <li className="parsley-required">{errorMessage}</li>
        </ul>
      ) : null}
    </>
  );
};

export default Input;
