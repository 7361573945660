import clsx from "clsx";
import Spinner from "component/spinner/spinner";
import React from "react";
import { SpinnerCircularFixed, SpinnerCircularSplit } from "spinners-react";

const Button = ({ className, variant = "primary", ...props }) => {
  return (
    <button
      className={`btn btn-${variant} btn-lg btn-block ${className}`}
      {...props}
    >
      {props.loader ? (
        <span>
          <div
            class={clsx("spinner-border ")}
            style={{ width: "1.2rem", height: "1.2rem" }}
            role="status"
          ></div>
        </span>
      ) : (
        props.children
      )}
    </button>
  );
};

export default Button;
