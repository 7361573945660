// import Helper from "./helper";
import { Subject } from "rxjs";
export const userGroupsOptionList = ["Admin", "Agent"];
export const DarkLogo = "assets/images/logos/astradial-logo.png";
export const LightLogo = "assets/images/logos/astradial-logo-light.png";
export const BACK_DELETE =
  "assets/images/logos/close-arrow-shape-button-interface-symbol.png";
export const consumer_key = "ck_9caa35326a6e60bcb27db7334298731beeb0752d";
export const consumer_secret = "cs_0416f00e8d3a2dba54159a9f095a76fd011d2dd9";
export const awsBaseUrl = "https://producerdj.s3.us-west-1.amazonaws.com/";
export const baseApiUrl = "https://producerdj.com";
export const redirectionLink = "/admin/dashboard";

export const profileSubject = new Subject();
