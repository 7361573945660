import axiosInstance from "service/axios";
import EventEmitter from "./eventEmiter";

export default class Helper extends EventEmitter {
  /**
   *
   * @returns access token
   */
  static getToken = () => {
    return localStorage.getItem("custom_access_token");
  };

  static showMessage = () => {
    return;
  };

  static getUserId = () => {
    const data = localStorage.getItem("root:data");
    try {
      const userData = JSON.parse(data);
      return userData?.ID;
    } catch (e) {
      return "";
    }
  };

  static parseString = (name = "") => {
    return name?.replaceAll("[\\-\\+\\.\\^:,]", "")?.replaceAll(" ", "-");
  };

  static updateUserData = (key, value) => {
    const data = localStorage.getItem("root:data");
    try {
      const userData = JSON.parse(data);
      userData["data"][key] = value;
      localStorage.setItem("root:data", JSON.stringify(userData));
    } catch (e) {
      return {};
    }
  };

  static getUserData = () => {
    const data = localStorage.getItem("root:data");
    try {
      const userData = JSON.parse(data);
      return userData;
    } catch (e) {
      return {};
    }
  };

  static redirectologin = () => {
    const authData = this.getUserData();
    if (!authData?.data?.user_url) {
      return true;
    }
    return false;
  };

  static getRole = () => {
    const data = localStorage.getItem("root:data");
    try {
      const userData = JSON.parse(data);
      return userData?.roles[0];
    } catch (e) {
      return "";
    }
  };

  static uploadDocument = () => {
    return axiosInstance.get("");
  };

  static showMessage = (message) => {
    this.emit("message", message);
  };

  /**
   *
   * @param {user level} user_level
   * @returns string :  what type of user
   */
  static getUserRole(user_level) {
    let user_role = "guest";
    if (
      user_level &&
      (user_level === "1" ||
        user_level === "2" ||
        user_level === "3" ||
        user_level === "4")
    ) {
      user_role = "agent";
    } else if (user_level && user_level === "9") {
      user_role = "admin";
    }
    return user_role;
  }

  static getFormData(object = {}, empty = false) {
    const formData = new FormData();
    Object.keys(object).forEach((key) =>
      formData.append(
        key,
        typeof object[key] != "undefined" && object[key] !== null
          ? object[key]
          : ""
      )
    );
    return formData;
  }

  static validateInputField(value, min, max) {
    if (value.length >= min && value.length <= max) {
      return true;
    }
    return false;
  }

  /**
   *
   * @returns for the generate uniq id
   */

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  static hasNumber(number) {
    const re = /^[-+]?[0-9]+$/;
    return re.test(String(number));
  }

  static logout() {
    localStorage.removeItem("root:data");
    window.location.href = "/";
  }

  static pad(num) {
    return ("0" + num).slice(-2);
  }

  static hhmmss(secs, convert = true) {
    if (convert) {
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    } else {
      return secs;
    }

    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  /**
   *
   * @returns this function is check for the idea time out ,
   */

  static checkIdleTimeOut() {
    const userToken = localStorage.getItem("custom_access_token");
    const isTimedOut = localStorage.getItem("isTimedOut");
    let isTimeOutBool = false;
    // useDeepCompareEffect(() => {
    //   if (userToken && isTimedOut) {
    //     // History.push('/pages/auth/lock');
    //     isTimeOutBool = true;
    //   }
    // }, [History]);
    return isTimeOutBool;
  }
  render() {
    // console.log("helper");
    return null;
  }
}
