import Helper from "utils/helper";
const { default: axiosInstance } = require("./axios");

export const getProfile = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};

export const changePassword = (data) => {
  return axiosInstance.post(
    "/api/product-image-test.php",
    Helper.getFormData(data)
  );
};

export const updateProduct = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};

export const updateDomain = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};
