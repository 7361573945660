import Helper from "utils/helper";

const { default: axiosInstance } = require("./axios");

export const login = (data) => {
  return axiosInstance.post("/api/login.php", Helper.getFormData(data));
};

export const Registration = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};

export const forgotPassword = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};

export const resetPassword = (data) => {
  return axiosInstance.post("/api/vendor.php", Helper.getFormData(data));
};
