import { S3_BUCKET } from 'hoc/filter-upload-wrapper';
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
export default function ListFolder({files=[],path}) {
    const [filteredFiles,setFilteredFiles] = useState({folders:[],files:[]})
    useEffect(()=>{

        
      
        let folders = [];
        let Mediafiles = [];
       

        for(let i=0;i<files?.length;i++){

          let folder = files[i]?.Key?.split("/");
            console.log("wdqwdw",folder,folder?.length,path.length+1)

          if(folder?.length>=path.length+1){
            folder = folder[path.length-1];
            if(!folders?.includes(folder)){
              folders.push(folder)
            }  
          }else{
            Mediafiles.push(files[i])
          }
         
        }

        

        setFilteredFiles({
            folders,
            Mediafiles
        })
       
    
    },[files]) 


  return (
  
        <div className="list px-0">
            {/* {filteredFiles.folders?.map((folder)=>{
                return <div>
                    <a href={`/admin/dashboard/${folder}`}>{folder}</a>
                </div>
            })} */}
              <div className="files">
              <ul className='files-wrapper'>
                  {filteredFiles?.folders?.map((folder)=>{
                    let pathD =path?.slice(1).map((item)=>item.name).join("/")
                    return <li className='folder-a'>
                        <a className='d-flex align-items-center  justify-content-between w-100' href={`/admin/dashboard${pathD?`/${pathD}`:""}/${folder}`}>
                            <div>
                           <span><i className='fa fa-file'></i></span> {folder}
                            </div>
                            <div style={{width:"30px", height:"30px"}} className='icon-btn ml-2 border d-flex justify-content-center align-items-center  rounded-circle'>
                                >
                            </div>
                        </a>
                        
                    </li>
                  })}
                  {filteredFiles?.Mediafiles?.map((folder)=>{
                let filename = folder.Key?.split("/").pop()
                let link = `https://${S3_BUCKET}.s3.amazonaws.com/${folder.Key}`;
                    return <li>
                        
                        <div className='d-flex align-items-center  justify-content-between w-100' >
                            
                        <div className='link' onClick={()=>{
                            window.open(link,"_blank")
                        }}>
                        {filename}
                            </div>
                            <CopyToClipboard text={link}
                            onCopy={() =>{
                                toast.success(link+" "+"Copied");
                            }}>
                      
                             <div style={{width:"30px", height:"30px",cursor:"pointer"}} className='icon-btn ml-2 border d-flex justify-content-center align-items-center  rounded-circle'>
                            
                             <i className='fa fa-link'></i>
                           
                           </div>
   
                           </CopyToClipboard> </div>
                
                    </li>
                  })}
              </ul>
              
            </div>
              {/* {filteredFiles.Mediafiles?.map((file,index)=>{
                return <div>
                    <a href={`/admin/dashboard/${index}`}>{index}</a>
                </div>
            })} */}
        </div>
   
  )
}
