import App, { ConfigRoute } from "App";
import { lazy } from "react";
import CustomerUpload from "view/customer-upload/customer-upload";
import Dashbord from "view/dashbord/Dashbord";
import ForgotPassword from "view/forgot-password";
import Login from "view/login";
import Register from "view/register";
import ResetPassword from "view/reset-password";
// import Dashbord from "./view/agent-dashboards/ProjectAgentDashboardApp";

/**
 * cms routes :   all cms routes define here
 */

/**
 * cms routes :bradcums routes for the inner page
 */

const breadcrumb = {
  
};

export const cmsRoutes = [
  {
    component: App,
    routes: [
      {
        path: "/upload/:email/:firstName",
        exact: true,
        component: CustomerUpload,
      },
      {
        path: "/admin/",
        exact: true,
        component: Login,
      },
      {
        path: "/admin/dashboard/:params1?/:params2?/:params3",
        exact: true,
        component: Dashbord,
      },
    ]}]

export const sideMenu = [
  
];

// export default navigationConfig;
