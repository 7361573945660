import { baseApiUrl, consumer_key, consumer_secret } from "utils/constant";
import Helper from "utils/helper";
const { default: axiosInstance } = require("./axios");

export const getAllProducts = (data) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

export const deleteProduct = (data) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

export const updateProduct = (data) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

export const getAllOrders = (data) => {
  return axiosInstance.post(`/api/orders.php`, Helper.getFormData(data));
};

export const getProduct = (data) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

export const getAllProductFile = (data = {}) => {
  return axiosInstance.post(
    `https://producerdj.com/manage/aws/product.php`,
    Helper.getFormData(data)
  );
};

export const getAllVenderList = (data = {}) => {
  return axiosInstance.post(`/api/product.php`, Helper.getFormData(data));
};

export const productApi = (data = {}) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

export const getCategories = () => {
  return axiosInstance.get(
    `${baseApiUrl}/wp-json/wc/v3/products/categories?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}`
  );
};

export const uploadCategories = (data = {}) => {
  return axiosInstance.post(
    `/api/product-image-test.php`,
    Helper.getFormData(data)
  );
};

// export const orderDetails = (data) => {
//   return axiosInstance.post(`/api/orders.php`, Helper.getFormData(data));
// };

export const orderDetails = (id) => {
  return axiosInstance.get(
    `${baseApiUrl}/wp-json/wc/v3/orders/${id}?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}`
  );
};
