// css
import "bootstrap/scss/bootstrap.scss";
import "font-awesome/scss/font-awesome.scss";
// import Auth from "hoc/auth";
import React from "react";
import ReactDOM from "react-dom";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import { cmsRoutes } from "routes";
import "./assets/assets/scss/color_skins.scss";
import "./assets/assets/scss/main.scss";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    {/* <Auth> */}
    <BrowserRouter>{renderRoutes(cmsRoutes)}</BrowserRouter>
    {/* </Auth> */}
  </React.StrictMode>,
  document.getElementById("wrapper")
);
