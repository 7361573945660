import React from "react";
import clsx from "clsx";

const Select = (props) => {
  const {
    dom = {},
    label = "",
    error = "",
    errorMessage,
    options = [],
    prependText = "",
    onChange,
    wrapperClass = "",
    required = false,
  } = props;
  return (
    <>
      <div
        className={clsx(
          "form-group ",
          wrapperClass,
          prependText ? "input-group" : ""
        )}
      >
        {label && (
          <label className="">
            {label}
            {required === true ? <span className="text-danger">*</span> : null}
          </label>
        )}
        {prependText && (
          <div className="input-group-prepend">
            <label className="input-group-text">{prependText}</label>
          </div>
        )}
        <select
          onChange={(e) => {
            const value = e.target.value;
            if (dom.placeholder != value) {
              onChange && onChange(value);
            }
          }}
          {...dom}
          className={`custom-select ${dom.className || ""}  ${
            error && "parsley-error"
          }  `}
        >
          <option className="">{dom.placeholder}</option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
      {error ? (
        <ul className="parsley-errors-list filled" id="parsley-id-29">
          <li className="parsley-required">{errorMessage}</li>
        </ul>
      ) : null}
    </>
  );
};

export default Select;
