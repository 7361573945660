// import ReCAPTCHA from "react-google-recaptcha";
// import { useDispatch, useSelector } from "react-redux";
// import { customSubmitLogin } from "store/auth/loginSlice";
// import { openDialog } from "store/ui/dialogSlice";
import Button from "component/ui/button";
import Card from "component/ui/card/card";
import Input from "component/ui/form/input";
import { Logo } from "config/image";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Redirect, useLocation, useParams, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { resetPassword } from "service/login";
import { redirectionLink } from "utils/constant";
import Helper from "utils/helper";
// import { SITE_KEY } from "utils/constant";
import * as yup from "yup";
const ResetPassword = (props) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const search = useLocation().search;
  const { addToast } = useToasts();
  const [pageType, setPageType] = useState("");
  const history = useHistory();

  const email = new URLSearchParams(search).get("email");
  const formik = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      password: yup.string("Enter Password").required("Password is required"),
      confirmPassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      email: yup
        .string("Enter Email")
        .email("Enter Valid Email")
        .required("Email is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const resetPasswordData = await resetPassword({
          email: values?.email,
          pwd: values?.password,
          act: "frgtPwd",
        });

        try {
          addToast(resetPasswordData?.data, { appearance: "success" });
          history.push("/");
        } catch (e) {}
        console.log("");
        setLoader(false);
      } catch (e) {
        setLoader(false);
      }
    },
  });

  if (Helper?.getUserId()) {
    return <Redirect to={redirectionLink}></Redirect>;
  }
  return pageType === "success" ? (
    <div>
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle register-success auth-main">
            <Card bodyClass="success-card" className="position-relative  w-fit">
              <svg
                id="successAnimation"
                className="animated"
                xmlns="http://www.w3.org/2000/svg"
                width={200}
                height={200}
                viewBox="0 0 70 70"
              >
                <path
                  id="successAnimationResult"
                  fill="#D8D8D8"
                  d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
                />
                <circle
                  id="successAnimationCircle"
                  cx={35}
                  cy={35}
                  r={24}
                  stroke="#979797"
                  strokeWidth={2}
                  strokeLinecap="round"
                  fill="transparent"
                />
                <polyline
                  id="successAnimationCheck"
                  stroke="#979797"
                  strokeWidth={2}
                  points="23 34 34 43 47 27"
                  fill="transparent"
                />
              </svg>

              <div className="text-center mt-3">
                {/* <h5 className=" mb-1">Thanks for the registration</h5> */}
                <h5>Please check your email to complete your registration</h5>
                <div className="mt-5">
                  <p
                    onClick={() => {
                      window.location.href = "/apps/login";
                    }}
                    className="btn btn-primary  mr-1 w-100p"
                  >
                    Login
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <form onSubmit={formik.handleSubmit}></form>
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src={Logo} alt="Lucid" style={{ width: "200px" }} />
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Change Password</p>
                </div>
                <div className="body">
                  <form onSubmit={formik.handleSubmit}>
                    <Input
                      dom={{
                        className: "form-control",
                        name: "email",
                        placeholder: "Email",
                        type: "text",
                        value: formik.values.email,
                        disabled: true,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      errorMessage={formik.errors.email}
                    ></Input>
                    <Input
                      errorMessage={formik.errors.password}
                      dom={{
                        className: "form-control",
                        name: "password",
                        placeholder: "Password",
                        type: "password",
                        value: formik.values.password,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    ></Input>
                    <Input
                      errorMessage={formik.errors.confirmPassword}
                      dom={{
                        className: "form-control",
                        name: "confirmPassword",
                        placeholder: "Confirm Password",
                        type: "password",
                        value: formik.values.confirmPassword,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                    ></Input>

                    <Button
                      className="mt-2"
                      type="submit"
                      disabled={!formik.isValid || !formik?.values?.password}
                      loader={loader}
                    >
                      Change Password
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
