import clsx from "clsx";
import Button from "component/ui/button";
import Input from "component/ui/form/input";
import Select from "component/ui/form/select";
import React, { useEffect, useState } from "react";
import { useRowSelect, useSortBy, useTable, usePagination } from "react-table";

function Table({
  columns,
  data,
  rowClick,
  rowClass,
  headerClass,
  rowSelection,
  parseTableData,
}) {
  const [tableData, setTableData] = useState([]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize = 10, selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,

      // defaultPageSize: 30,
      // pageSize: "30",
    },

    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (rowSelection) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection

          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    let parseData = [];
    if (parseTableData) {
      parseData = data?.map((row) => {
        return parseTableData(row);
      });
    } else {
      parseData = data;
    }

    setTableData(parseData);
  }, [data]);

  // Render the UI for your table
  return (
    <div className="pb-5 relative ">
      <table {...getTableProps()} className="  table ">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              style={{ position: "sticky", top: 0, background: "white" }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  title={column?.sort ? "sort" : ""}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={clsx(
                    headerClass,
                    column?.headerClass || "",
                    "py-3 border-none"
                  )}
                  style={{ border: "none" }}
                >
                  <div className="">
                    <div className="">{column.render("Header")}</div>
                    {column?.sort &&
                      (column.isSorted ? (
                        column?.sort && (
                          <div className="" title="sort">
                            {/* <Sort
                              className={column.isSortedDesc ? "" : "rot180"}
                            ></Sort> */}
                          </div>
                        )
                      ) : (
                        <div className="ml-3 opacity-0">
                          {/* <Sort className={""}></Sort> */}
                        </div>
                      ))}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className={rowClass}
                {...row.getRowProps({
                  onClick: (e) => rowClick && rowClick(row, i, e),
                })}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex pagination-div absolute align-items-center pagination-form justify-content-end mr-3">
        <div className="">
          <button
            className="btn border py-2"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            className="btn border py-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>{" "}
          <button
            className="btn border py-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>{" "}
          <button
            className="btn border py-2"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
        <div></div>
        <div className="d-flex align-items-center mx-3">
          <div>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </div>
          <div className="d-flex align-items-center">
            <div className="mr-3">&nbsp;|&nbsp;Go to page: </div>
            <Input
              dom={{
                onChange: (e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                },
                className: "mb-0",
                value: pageIndex + 1,
                type: "number",
                style: { width: "80px" },
              }}
            />
          </div>{" "}
        </div>
        <Select
          options={[10, 20, 30, 40, 50]?.map((option) => {
            return {
              label: option,
              value: option,
            };
          })}
          dom={{
            placeholder: "Size",
            value: pageSize || 10,
          }}
          onChange={(value) => {
            setPageSize(Number(value));
          }}
        ></Select>
      </div>
    </div>
  );
}

export default Table;
