import "bootstrap/dist/css/bootstrap.min.css";
import clsx from "clsx";
import { Logo2, PLACEHOLDER } from "config/image";
import moment from "moment";
// import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Nav } from "react-bootstrap";

// import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { sideMenu } from "routes";
import { profileSubject } from "utils/constant";
import Helper from "utils/helper";
// import { logoutUser } from "store/auth/userSlice";

const Header = (props) => {
  // const dispatch = useDispatch();
  // var toggle = false;

  const disabled = false;
  const [toggle, setToggle] = useState(false);
  const [date, setDate] = React.useState(new Date());

  function tick() {
    setDate(new Date());
  }

  const onPressSideMenuToggle = async () => {
    // toggle = !toggle;
    await setToggle(!toggle);

    if (toggle) {
      document.body.classList.add("offcanvas-active");
    } else {
      document.body.classList.remove("offcanvas-active");
    }
  };

  return (
    <nav
      className={clsx("navbar navbar-fixed-top", disabled ? "notallowed" : "")}
    >
      <div className="container-fluid">
        <div className="navbar-btn">
          <button
            className="btn-toggle-offcanvas"
            onClick={() => {
              onPressSideMenuToggle();
            }}
          >
            <i className="lnr lnr-menu fa fa-bars"></i>
          </button>
        </div>

        <div className="navbar-brand">
          <img
            src={document.body.classList.contains("full-dark") ? Logo2 : Logo2}
            alt="Lucid Logo"
            style={{
              width: "210px",
              // height: "32px",
            }}
            className="img-responsive logo"
          />
        </div>
        <div className="navbar-right">
          <div id="navbar-menu">
            <ul className="nav navbar-nav">
              <li
                onClick={() => {
                  // dispatch(logoutUser());
                  localStorage.removeItem("root:data");
                  // localStorage.removeItem("custom_access_token");
                  window.location.href = "/";
                }}
              >
                <div className="icon-menu cursor-pointer">
                  <button className=" px-3 btn d-flex justify-content-center align-items-center btn btn-prymary btn-lg btn-block undefined  mr-1">
                    <i className="icon-login cursor-pointer text-white"></i>
                    &nbsp; Logout
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const SideMenu = (props) => {
  // const dispatch = useDispatch();
  const disabled = false;
  const history = useHistory();
  const { route = {} } = props;
  const [openMenu, setMenu] = useState(route.layout);
  const [data, setData] = useState(Helper?.getUserData()?.data);

  // const { userData = {} } = useAuth();
  const userData = {};
  // const { data = {} } = userData;
  const activeMenutabContainer = (id) => {
    var parents = document.getElementById("main-menu");
    var activeMenu = document.getElementById(id);

    setTimeout(() => {
      activeMenu.classList.toggle("active");
      activeMenu.children[1].classList.toggle("in");
    }, 10);
  };

  useEffect(() => {
    profileSubject.subscribe((profile) => {
      Helper.updateUserData("usrImage", profile?.data?.[0]?.usrImage);
      Helper.updateUserData("first_name", profile?.data?.[0]?.first_name);
      Helper.updateUserData("last_name", profile?.data?.[0]?.last_name);
      Helper.updateUserData("user_nicename", profile?.data?.[0]?.user_nicename);

      setData({
        ...data,
        ...(profile?.data?.[0] || {}),
      });
    });
  }, []);
  return (
    <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
      <div className="sidebar-scroll">
        <div className="user-account">
          <img
            src={data?.usrImage?.url || PLACEHOLDER}
            className="rounded-circle user-photo user-avatar"
            alt="User Profile Picture"
          />
          {/* Manish */}
          <Dropdown>
            <span>Welcome,</span>
            <Dropdown.Toggle
              variant="none"
              as="a"
              id="dropdown-basic"
              className={clsx(
                "user-name cursor-pointer",
                disabled ? "notallowed" : ""
              )}
            >
              <strong>
                {data?.user_nicename || data?.display_name || data?.email}
              </strong>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className={clsx(
                "dropdown-menu-right account",
                disabled ? "notallowed" : ""
              )}
            >
              <Dropdown.Item
                onClick={() => {
                  history.push(`/apps/profile`);
                }}
              >
                <i className="icon-user"></i>My Profile
              </Dropdown.Item>

              <li className="divider"></li>
              <Dropdown.Item
                className={clsx("", disabled ? "notallowed" : "")}
                onClick={() => {
                  Helper?.logout();
                }}
              >
                {" "}
                <i className="icon-power"></i>Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="tab-content pt-0 p-l-0 p-r-0">
          <div className={"tab-pane active show"} id="menu">
            <Nav id="left-sidebar-nav" className="sidebar-nav">
              <ul id="main-menu" className={clsx("metismenu")}>
                {sideMenu &&
                  Array.isArray(sideMenu) &&
                  sideMenu.map((menu, key) => {
                    return !menu.auth || userData.role == menu.auth ? (
                      menu.childrens ? (
                        <li
                          className={clsx(
                            route.layout == menu.id ? "active" : "",
                            disabled ? "notallowed" : ""
                          )}
                          id={menu.id}
                        >
                          <a
                            className={clsx(
                              "has-arrow",
                              disabled ? "notallowed" : ""
                            )}
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              setMenu(openMenu == menu.id ? "" : menu.id);
                            }}
                          >
                            <i className={menu.icon}></i>{" "}
                            <span>{menu.title}</span>
                          </a>
                          <ul
                            className={clsx(
                              `collapse  ${openMenu == menu.id ? "in" : ""}`,
                              disabled ? "notallowed" : ""
                            )}
                          >
                            {menu.childrens.map((subMenu) => {
                              return (
                                <li
                                  className={clsx(
                                    route.id === subMenu.id ? "active" : "",
                                    disabled ? "notallowed" : ""
                                  )}
                                >
                                  <Link
                                    className={clsx(
                                      disabled ? "notallowed" : ""
                                    )}
                                    to={subMenu.path}
                                  >
                                    {subMenu.title}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : (
                        <li
                          id={`dashboradContainer_${key}`}
                          className={clsx(
                            route.layout == menu.id ? "active" : "",
                            disabled ? "notallowed" : ""
                          )}
                        >
                          <Link
                            className={clsx(disabled ? "notallowed" : "")}
                            to={menu.path}
                          >
                            <i className={menu.icon}></i>{" "}
                            <span>{menu.title}</span>
                          </Link>
                        </li>
                      )
                    ) : (
                      ""
                    );
                  })}
              </ul>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
};
class NavbarMenu extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <SideMenu {...this.props}></SideMenu>
      </div>
    );
  }
}

// const mapStateToProps = ({ navigationReducer }) => {
//   const { sideMenuTab } = navigationReducer;
//   return {
//     sideMenuTab,
//   };
// };

// export default withRouter(NavbarMenu);
// mapStateToProps, {
// onPressSideMenuToggle,
// onPressSideMenuTab,
// }

export default NavbarMenu;
