import React from "react";

const Checkbox = (props) => {
  const { dom = {}, label } = props;
  return (
    <div className="fancy-checkbox mb-0">
      <label className="mb-0">
        <input type="checkbox" {...dom} />
        <span>{label || ""}</span>
      </label>
    </div>
  );
};

export default Checkbox;
