import React, { Suspense, useEffect } from "react";
import { matchRoutes, renderRoutes } from "react-router-config";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import NavbarMenu from "component/ui/navbar/navbar";
import Notification from "component/ui/notification/Notification";
// import CustomDialog from "dialog/dialog";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Helper from "utils/helper";
import { ToastProvider } from "react-toast-notifications";
import Register from "view/register";
import FileUploadWrapper from "hoc/filter-upload-wrapper";
import Success from "view/success";
import ForgotPassword from "view/forgot-password";
import ResetPassword from "view/reset-password";
import CustomerUpload from "view/customer-upload/customer-upload";
import SampleFile from "view/sample-file/sample-files"
import Login from "view/login";
import Dashbord from "view/dashbord/Dashbord";
const App = (props) => {
  let { route } = props;
  const location = useLocation();
  const path = window.location.pathname;
  let currentPath = matchRoutes(route.routes, location.pathname);
  let routeD = currentPath[0];

  return (
    <>
      <ToastContainer></ToastContainer>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={2000}
        placement="bottom-center"
      >
        {location.pathname?.includes("/registration") ? (
          <Register></Register>
        ) : location.pathname?.includes("/forgot-password") ? (
          <ForgotPassword></ForgotPassword>
        ) : location.pathname?.includes("/reset-password") ? (
          <ResetPassword></ResetPassword>
        ) : !location.pathname?.includes("/product") &&
          location.pathname?.includes("/success") ? (
          <Success></Success>
        ):location.pathname?.includes("/admin/dashboard") ?
             Helper.getUserId()? <FileUploadWrapper><Dashbord {...routeD}></Dashbord></FileUploadWrapper>: <Redirect to="/admin"></Redirect>
        :location.pathname?.includes("/admin") ?<Login></Login>:  (
          <div className="">
            <FileUploadWrapper>
              {/* <div>
                <NavbarMenu {...routeD}></NavbarMenu>
              </div> */}
              <div>
                <div className="pt-0">
                  <div id="main-content" className="d-flex flex-column">
                    <div className="main-div">
                      <Suspense fallback={() => "loading..."}>
                        {renderRoutes(route.routes)}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </FileUploadWrapper>
          </div>
        )}
      </ToastProvider>
    </>
  );
};

export default withRouter(App);
