// import ReCAPTCHA from "react-google-recaptcha";
// import { useDispatch, useSelector } from "react-redux";
// import { customSubmitLogin } from "store/auth/loginSlice";
// import { openDialog } from "store/ui/dialogSlice";
import Button from "component/ui/button";
import Input from "component/ui/form/input";
import { Logo } from "config/image";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "service/login";
import { getAllProducts } from "service/product";
import { getProfile } from "service/profile-service";
import { redirectionLink } from "utils/constant";
import Helper from "utils/helper";
import * as yup from "yup";

const Login = (props) => {


  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      password: yup.string("Enter Password").required("Password is required"),
      email: yup.string("Enter Username").required("Username is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoader(true);

      if(values.email==="admin" && values.password==="aJ573^#^chQwwPtE"){
        localStorage.setItem("root:data",JSON.stringify({ID:Date.now()}));
        window.location.href = redirectionLink;
      }else{

          formik.setErrors({
          email: "Invalid Username",
          password: "Invalid Password",
        });
        setLoader(false);

      }
      // try {
      //   const loginData = await login({
      //     username: values?.email,
      //     pwd: values?.password,
      //   });

      //   if (loginData?.data && typeof loginData?.data === "object") {
      //     try {
      //       const response = await getProfile({
      //         uid: loginData?.data?.ID,
      //         act: "getUserInfo",
      //       });
      //       localStorage.setItem(
      //         "root:data",
      //         JSON.stringify({
      //           ...loginData?.data,
      //           data: {
      //             ...(response?.data?.[0] || {}),
      //             user_url: response?.data?.[0]?.pdj_domain,
      //           },
      //         })
      //       );
      //       window.location.href = redirectionLink;
      //     } catch (e) {}
      //   } else {
      //     formik.setErrors({
      //       email: loginData?.data,
      //     });
      //   }

      //   console.log("");
      //   setLoader(false);
      // } catch (e) {
   
      // }
    },
  });

  const formRef = useRef(null);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {}

  const onChange = async (value) => {};

  useEffect(() => {
    getAllProducts();
  }, []);


  if (Helper?.getUserId()) {
    return <Redirect to={redirectionLink}></Redirect>;
  }
  return (
    <div>
      <form onSubmit={formik.handleSubmit}></form>
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src={Logo} alt="Lucid" style={{ width: "200px" }} />
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Login to your account</p>
                </div>
                <div className="body">
                  <form onSubmit={formik.handleSubmit}>
                    <Input
                      dom={{
                        className: "form-control",
                        name: "email",
                        placeholder: "User E-mail",
                        type: "text",
                        value: formik.values.email,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      errorMessage={formik.errors.email}
                    ></Input>
                    <Input
                      errorMessage={formik.errors.password}
                      dom={{
                        className: "form-control",
                        name: "password",
                        placeholder: "Password",
                        type: "password",
                        value: formik.values.password,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    ></Input>
                   
                    <Button
                      className="mt-4"
                      type="submit"
                      disabled={!formik.isValid}
                      loader={loader}
                    >
                      Login
                    </Button>
                  </form>
                 ̦
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
