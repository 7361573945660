import clsx from "clsx";
import Constant from "config/constant";
import { React, useState } from "react";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
  const { backButton = true, title = "", Breadcrumb = [] } = props;
  const [toggleMenu, setToggleMenu] = useState(false);

  const onToggleMenu = async () => {
    await setToggleMenu(!toggleMenu);

    if (toggleMenu) {
      document.body.classList.remove("toggle-fullwidth");
    } else {
      document.body.classList.add("toggle-fullwidth");
    }
  };

  return (
    <div className="block-header">
      <div className="row">
        <div className="col-12">
          <h2>
            <a
              href="#!"
              className="btn btn-xs btn-link btn-toggle-fullwidth"
              onClick={(e) => {
                e.preventDefault();
                onToggleMenu();
              }}
            >
              <i
                className={
                  !toggleMenu ? `fa fa-arrow-left` : "fa fa-arrow-right"
                }
              ></i>
            </a>
            {title}
          </h2>

          {/* <h2 className="mb-2">
            {backButton && (
              <div
                className="btn btn-xs btn-link btn-toggle-fullwidth"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                <i className={backButton && `fa fa-arrow-left`}></i>
              </div>
            )}
            {title}
          </h2> */}
          {/* <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Constant.redirectionUrl}>
                <i className={clsx("icon-home ")}></i>
              </Link>
            </li>
            {Breadcrumb?.map((item, index) => {
              return (
                <li
                  key={item.name + index}
                  className={`breadcrumb-item  active ${
                    item.active && "active"
                  }`}
                >
                  <Link className={clsx("")} to={item.path ? item.path : null}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
