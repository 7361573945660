import Spinner from "component/spinner/spinner";
import Button from "component/ui/button";
import Pagination from "component/ui/pagination/pagination";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { fileOperation } from "service/file";
import { getAllProducts } from "service/product";
import Helper from "utils/helper";
import { getFiles } from "./sample-file-data";
import React, { Component } from "react";
import swal from "sweetalert";
import { useToasts } from "react-toast-notifications";
import Input from "component/ui/form/input";
import Table from "component/table/table";

const { default: Card } = require("component/ui/card/card");
const { default: Wrapper } = require("component/ui/wrapper/wrapper");

const Files = (props) => {
  const [FilesData, setFiles] = useState(null);
  const [FilterdFilesData, setFilteredFiles] = useState(null);

  const [row, setRowData] = useState({});
  const { addToast } = useToasts();
  const [search, setSearch] = useState("");

  const [show, setAlert] = useState(false);
  const history = useHistory();
  const getAllFiles = () => {
    fileOperation({
      uid: Helper?.getUserId() + "_sample",
      action: "getData",
    }).then((data) => {
      setFiles(data?.data?.result);
      setFilteredFiles(data?.data?.result);
    });
  };
  useEffect(() => {
    getAllFiles();
    return () => {};
  }, []);

  useEffect(() => {
    if (search) {
      const filtedFile = FilesData?.filter((prod) => {
        return (
          prod?.file_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          prod?.objectURL?.toLowerCase()?.includes(search?.toLowerCase())
        );
      });
      setFilteredFiles(filtedFile);
    } else {
      setFilteredFiles(FilesData);
    }
  }, [search]);
  const { route } = props;

  const deleteFile = (row) => {
    swal({
      title: "Are you sure You want to Delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fileOperation({
          key: row?.Key,
          action: "delete",
        }).then((data) => {
          addToast("Sample File deleted Successfully", {
            appearance: "success",
          });
          getAllFiles();
        });
      } else {
      }
    });
  };
  const file = getFiles(deleteFile);

  return (
    <div>
      <Wrapper
        title="Sample Audio Files"
        // Breadcrumb={route.breadcrumb}
      ></Wrapper>

      <div className="d-flex justify-content-end top-right">
        <div className="mr-3 search-input">
          <Input
            postFix={() => {
              return (
                <div>
                  <i className="icon-magnifier"></i>
                </div>
              );
            }}
            dom={{
              type: "text",
              value: search,
              onChange: (e) => setSearch(e?.target.value),
              style: { width: "300px" },
            }}
          ></Input>
        </div>
        <div>
          <Button
            className="px-4"
            onClick={() => {
              history?.push("/apps/sample-file");
            }}
          >
            Upload Sample Audio
          </Button>{" "}
        </div>
      </div>
      <Card className="" fixed={true}>
        <div className="d-flex justify-content-end"></div>
        <div className="h-100 w-100">
          {!FilterdFilesData ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <div>
                <Spinner></Spinner>
              </div>
            </div>
          ) : FilterdFilesData?.length == 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <h5>No Data Found</h5>
            </div>
          ) : (
            <div className="table-responsive  h-100    w-100">
              <Table
                columns={getFiles(deleteFile)}
                data={FilterdFilesData}
              ></Table>
            </div>
          )}
        </div>

        {/* <Pagination></Pagination> */}
      </Card>
    </div>
  );
};

export default Files;
