// import ReCAPTCHA from "react-google-recaptcha";
// import { useDispatch, useSelector } from "react-redux";
// import { customSubmitLogin } from "store/auth/loginSlice";
// import { openDialog } from "store/ui/dialogSlice";
import Button from "component/ui/button";
import Input from "component/ui/form/input";
import { Logo } from "config/image";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { forgotPassword } from "service/login";
import { redirectionLink } from "utils/constant";
import Helper from "utils/helper";
// import { SITE_KEY } from "utils/constant";
import * as yup from "yup";

const ForgotPassword = (props) => {
  //   const dispatch = useDispatch();
  //   const ForgotPassword = useSelector(({ auth }) => auth.login);
  //   const recaptchaRef = React.useRef();

  const [loader, setLoader] = useState(false);

  const { addToast } = useToasts();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string("Enter Username").required("Username is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const forgotPasswordData = await forgotPassword({
          email: values?.email,
          act: "rstPwd",
        });
        addToast(forgotPasswordData?.data, { appearance: "success" });
        history.push("/");
        // window.location.href = "/";
        setLoader(false);
      } catch (e) {
        formik.setErrors({
          email: "Invalid email",
        });
        setLoader(false);
      }
    },
  });

  const formRef = useRef(null);

  if (Helper?.getUserId()) {
    return <Redirect to={redirectionLink}></Redirect>;
  }
  return (
    <div>
      <form onSubmit={formik.handleSubmit}></form>
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src={Logo} alt="Lucid" style={{ width: "200px" }} />
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Forgot Password</p>
                </div>
                <div className="body">
                  <form onSubmit={formik.handleSubmit}>
                    <Input
                      dom={{
                        className: "form-control",
                        name: "email",
                        placeholder: "User E-mail",
                        type: "text",
                        value: formik.values.email,
                        onChange: formik.handleChange,
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      errorMessage={formik.errors.email}
                    ></Input>

                    <Button
                      className="mt-4"
                      type="submit"
                      disabled={!formik.isValid}
                      loader={loader}
                    >
                      Reset Password
                    </Button>
                  </form>
                  <div class="bottom mt-3 text-center">
                    <span>
                      Back To
                      <Link to="/">&nbsp;Login</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
