import assetsLogo from "../assets/images/pdj_white_logo.png";
import assetsLogo2 from "../assets/images/pdg-dark.png";
import userpng from "../assets/images/logo.svg";
import backdelete from "../assets/images/logo.svg";
import imgDJ from "../assets/images/samori-imixit2 (1).jpg";


export const Logo = assetsLogo;
export const Logo2 = assetsLogo2;


export const IMAGEDJ = imgDJ;

export const User = userpng;
export const BACK_DELETE = backdelete;

export const PLACEHOLDER = "/favicon.jpeg";
