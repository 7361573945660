import Button from "component/ui/button";
import Select from "component/ui/form/select";
import { FileContext } from "context/file-upload-context";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";
const fileTypes = ["MP3","WAV", "ZIP"];
export default function AdminUpload(props) {
      const { route ,toggle,path,fileUpload } = props;
  const params = useParams();
  const { id } = params;
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const [fileR, resetFile] = useState("r");
  const [downloadFile, setDownloadFiles] = useState([]);
  const [files,  setFiles] =  useState([])
  const [sampleFile, setSampleFile] = useState({});
  const { File, uploadFile , getBuckets,} = useContext(FileContext);
  const [email,setEmail] = useState("")
  const [folder,setFolder] = useState([])




 
  const getFolders = () => {
    getBuckets({}).then((data) => {
        setFolder(data)
     
      // filteredData(data);
    });
    return () => {};
  };
  useEffect(() => {
    getFolders();

    return () => {};
  }, []);

  
    const getInitialState = (state = {}) => {
      return {
        file_name: "",
        file: [],
        file_type: "product",
        folderName:""
      };
    };
    const formik = useFormik({
      initialValues: getInitialState(),
      validationSchema: yup.object({
        // file_name: yup
        //   .string("Enter File Name")
        //   .required("Please Enter File Name"),
        folderName:yup.string().required("Folder Name is required"),
        file: yup.mixed().required("File is required"),
        // email: yup.string("Enter email").required("Email is required"),
      }),
      onSubmit: async (values) => {
      Object.values( values.file)?.map((file)=>{
         
          let requestPayload = {
            fileToUpload: file, 
            file_type: file.file_type,
            folder:`${values.folderName}`,
            cb:()=>{
                fileUpload(path)
            }
          };
    
       
          formik.resetForm(getInitialState());
          uploadFile(
            requestPayload,
          
           
          );
        })
      
        resetFile(Date.now());
        
      },
    });
  
    const handleChange = (file) => {
      // setFile(file);
      file && formik.setFieldValue("file", file);

    };
  return (
   <div className='wrapper-file'>
        <div className='upload-block '>
      
              <form onSubmit={formik.handleSubmit}>
              <div className='drop-container card'>
                    <div className="drop-file-container card-body ">
                        <label>Product File</label>
                        <div className='upload-input mb-3 '>
                            <FileUploader

                              multiple={true}
                              handleChange={handleChange}
                              name="file"
                              types={fileTypes}
                            />
                            {/* <p style={{color:"red",fontSize:"12px"}}>Audio file should in mp3 and less than 5 MB</p> */}
                        </div>  
                        <div className='upload-input mb-3 mt-2'>
                      
                        <Select
                label={"Folder"}
                options={folder?.map((folder)=>{
                    return { label: `${folder}`, value: `${folder}/final` }
                })}
                 dom={{
                  name: "folderName",
                  onChange: formik.handleChange,
                  className: "mb-0",
                  placeholder: "Select folder",
                  value: formik?.values?.folderName,
                }}
                error={
                  formik.touched.folderName && Boolean(formik.errors.folderName)
                }
                errorMessage={
                  formik.touched.folderName && formik.errors.folderName
                }
              ></Select>
                            {/* <p style={{color:"red",fontSize:"12px"}}>Audio file should in mp3 and less than 5 MB</p> */}
                        </div>  
                        <div className="ml-0">
                          <ul className="pl-3"> 
                            <li className="insr">Please ensure that your audio files don't contain any malware or viruses </li>
                            <li className="insr">Do not close your tab or browser while uploading audio files, or you'll have to re-upload the files.</li>
                            <li className="insr">Only upload MP3, WAV, or Zip files.</li>
                            <li className="insr">The maximum file size allowed is 3 GB.</li>
                         
                           </ul>
                        </div>
                      <div className="row mt-3">
                        <div className="col">
                        <Button type="submit" disabled={!formik.values.file?.length>0}>
                          Upload 
                        </Button>
                        </div>
                        <div className="col">
                        <Button type="button" onClick={toggle} variant="danger">
                          Cancel 
                        </Button>
                        </div>
                      </div>
                    </div>
                </div>
              </form>

        </div>
     
   </div>
  )
}




