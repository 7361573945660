import React from "react";
import PageHeader from "./header";

const Wrapper = (props) => {
  const { backButton = true, title = "", Breadcrumb = [] } = props;

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid position-relative">
          <PageHeader
            backButton={backButton}
            title={title}
            Breadcrumb={Breadcrumb}
          />

          <div className="">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
