import clsx from "clsx";
import React from "react";

const Card = ({ className, titleClass, fixed, bodyClass, ...props }) => {
  return (
    <div
      className={`card    ${fixed ? "card-body-fixed" : ""} ${className || ""}`}
    >
      {props.title && (
        <div className={clsx("header", titleClass || "")}>
          {<h2>{props.title || ""}</h2>}
        </div>
      )}
      <div
        className={` h-100 body ${
          fixed ? "d-flex flex-column overflow-hidden" : ""
        } ${bodyClass || ""}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Card;
