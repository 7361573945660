import axios from "axios";
import { baseApiUrl } from "utils/constant";
import Helper from "utils/helper";

const request = {
  baseURL: baseApiUrl,
  headers: { "Content-Type": "application/json" },
};

const axiosInstance = axios.create(request);
axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  async (e) => {
    let config = e?.response?.config;
    if (e.response) {
      if (e?.response?.status === 500) {
      }
    }
    return Promise.reject(e);
  }
);

export default axiosInstance;
